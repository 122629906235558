<template>
  <div class="cases positionr">
    <!-- banner -->
    <div class="bannerBox positionr">
      <img
        src="../../assets/images/cases/banner.jpg"
        alt=""
        width="100%"
        class="banner"
      />
      <div class="banner_contB positiona hp100">
        <div class="w1200 flex align-center hp100">
          <div class="banner_cont animated bounceInLeft">
            <div class="f58">你的同行 都在用云蓝</div>
            <div class="f32 mt50 mb100">团队成功案例</div>
            <div class="downLoadBtn textc text-white f26 pointer transition">
              免费体验
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="w1200">
      <!-- tabbar部分 -->
      <div class="pb100">
        <!-- tabbar -->
        <div class="pt40 pb40 tabBox">
          <span
            class="f18 pr90 pointer fw700 tabItem"
            :class="[tabIndex == i ? 'text-blue' : '']"
            v-for="(item, i) in tabbarList"
            :key="item"
            @click="handleTab(i)"
            >{{ item }}</span
          >
        </div>
        <!-- 列表 -->
        <div class="flex align-center flex-wrap caseList mt20">
          <div
            class="caseItem textc pointer"
            v-for="(item, i) in caseList"
            :key="i"
            @click="seeDetails(item.id)"
          >
            <div class="casePic transition">
              <el-image
                :src="item.imgUrl"
                lazy
                class="transition"
                width="310"
              ></el-image>
              <!-- <img :src="item.imgUrl" alt="" width="310" class="transition" /> -->
            </div>
            <div class="case_tit f30 pt25 pb25 transition">{{ item.tit }}</div>
          </div>
        </div>
        <!-- 分页 -->
        <div class="textc pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="100"
            :page-size="12"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 免费咨询 -->
    <free-box></free-box>

    <!-- 详情 -->
    <el-dialog :visible.sync="detailDialog" width="1000px">
      <div>
        <!-- 标题 -->
        <div class="textc shopTit positionr">
          <img
            src="../../assets/images/cases/details1.png"
            width="120"
            height="120"
            alt=""
          />
          <div class="f18 text333 fw600">百联超美便利店</div>
          <div class="tips f12 mt10">合作门店127家</div>
        </div>
        <!-- 内容 -->
        <div class="shopInfo pt20 pb20">
          <vue-custom-scrollbar
            class="scroll-area "
            :settings="settings"
          
          >
          <div class="pl30 pr30">
              百联超美24H智能便利店，响应国家《关于完善促进消费体制机制，进一步激发居民消费潜力的若干意见》中明确提到的“大力发展便利店”的政策，依托集团在零售行业数十年的深耕和经营，将成熟的供应系统结合“智慧零售”新概念，颠覆传统便利店单一模式
              ，革新升级互联网+新零售便利店，领创便利店+奶茶复合商业模式，打造15分钟便民生活圈。
              百联超美便利店是移动互联网领域里领先的便利店平台。作为“实体店＋互联网”的典型，百联超美24小时便利店以互联网＋便利店的模式，在全国范围内掀起了一股新智能零售6.0转型的浪潮！“你需要时我都在”、“一日六餐”、“15分钟及时雨”是百联超美方便快捷，以用户为中心的完美体现。
              百联超美开拓了实体店铺、线上商城、社区服务、便民生活四大板块，实现了供应链—线上线下一体化—社区商业资源—百联超美便利店—消费者的完美闭环。成熟的模式和完善的运营，让百联超美成为便利店行业的佼佼者。
              百联超美24H智能便利店，响应国家《关于完善促进消费体制机制，进一步激发居民消费潜力的若干意见》中明确提到的“大力发展便利店”的政策，依托集团在零售行业数十年的深耕和经营，将成熟的供应系统结合“智慧零售”新概念，颠覆传统便利店单一模式
              ，革新升级互联网+新零售便利店，领创便利店+奶茶复合商业模式，打造15分钟便民生活圈。
              百联超美便利店是移动互联网领域里领先的便利店平台。作为“实体店＋互联网”的典型，百联超美24小时便利店以互联网＋便利店的模式，在全国范围内掀起了一股新智能零售6.0转型的浪潮！“你需要时我都在”、“一日六餐”、“15分钟及时雨”是百联超美方便快捷，以用户为中心的完美体现。
              百联超美开拓了实体店铺、线上商城、社区服务、便民生活四大板块，实现了供应链—线上线下一体化—社区商业资源—百联超美便利店—消费者的完美闭环。成熟的模式和完善的运营，让百联超美成为便利店行业的佼佼者。
              百联超美24H智能便利店，响应国家《关于完善促进消费体制机制，进一步激发居民消费潜力的若干意见》中明确提到的“大力发展便利店”的政策，依托集团在零售行业数十年的深耕和经营，将成熟的供应系统结合“智慧零售”新概念，颠覆传统便利店单一模式
              ，革新升级互联网+新零售便利店，领创便利店+奶茶复合商业模式，打造15分钟便民生活圈。
              百联超美便利店是移动互联网领域里领先的便利店平台。作为“实体店＋互联网”的典型，百联超美24小时便利店以互联网＋便利店的模式，在全国范围内掀起了一股新智能零售6.0转型的浪潮！“你需要时我都在”、“一日六餐”、“15分钟及时雨”是百联超美方便快捷，以用户为中心的完美体现。
              百联超美开拓了实体店铺、线上商城、社区服务、便民生活四大板块，实现了供应链—线上线下一体化—社区商业资源—百联超美便利店—消费者的完美闭环。成熟的模式和完善的运营，让百联超美成为便利店行业的佼佼者。
            </div>
          </vue-custom-scrollbar>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import freebox from "@/components/freeBox";
import vueCustomScrollbar from "vue-custom-scrollbar";
import "vue-custom-scrollbar/dist/vueScrollbar.css";
export default {
  data() {
    return {
      tabbarList: ["全部", "餐饮", "零售", "生鲜", "茶饮"],
      tabIndex: 0,
      caseList: [
        {
          id: 1,
          imgUrl: require("../../assets/images/cases/case_1.png"),
          tit: "百联超美",
        },
        {
          id: 2,
          imgUrl: require("../../assets/images/cases/case_2.png"),
          tit: "奥莱华联",
        },
        {
          id: 3,
          imgUrl: require("../../assets/images/cases/case_3.png"),
          tit: "淘果居",
        },
        {
          id: 4,
          imgUrl: require("../../assets/images/cases/case_4.png"),
          tit: "中和广",
        },
        {
          id: 5,
          imgUrl: require("../../assets/images/cases/case_5.png"),
          tit: "南翔小笼",
        },
        {
          id: 6,
          imgUrl: require("../../assets/images/cases/case_6.png"),
          tit: "味食记",
        },
        {
          id: 7,
          imgUrl: require("../../assets/images/cases/case_7.png"),
          tit: "海峰私厨",
        },
        {
          id: 8,
          imgUrl: require("../../assets/images/cases/case_8.png"),
          tit: "不二家粉馆",
        },
        {
          id: 9,
          imgUrl: require("../../assets/images/cases/case_9.png"),
          tit: "川味金记",
        },
        {
          id: 10,
          imgUrl: require("../../assets/images/cases/case_10.png"),
          tit: "衢州小吃",
        },
        {
          id: 11,
          imgUrl: require("../../assets/images/cases/case_11.png"),
          tit: "馒道",
        },
        {
          id: 12,
          imgUrl: require("../../assets/images/cases/case_12.png"),
          tit: "点火锅",
        },
      ],
      detailDialog: false,
      settings: {
        suppressScrollY: false,
        suppressScrollX: false,
        wheelPropagation: false,
      },
    };
  },
  components: {
    "free-box": freebox,
    vueCustomScrollbar,
  },
  methods: {
    // 监听tabbar切换
    handleTab(i) {
      this.tabIndex = i;
    },
    // 查看详情
    seeDetails(id) {
      this.detailDialog = true;
    },
  },
};
</script>

<style scoped>
.cases {
  min-height: 100vh;
  background-color: #f5f5f5;
}
.banner_contB {
  left: 0;
  top: 0;
  width: 100%;
}
.tabItem:hover {
  color: #0084ff;
}
.caseList .caseItem {
  margin-right: 130px;
}
.caseList .caseItem {
  margin-right: 130px;
  margin-bottom: 30px;
}
.caseList .caseItem:nth-child(3n) {
  margin-right: 0;
}
.casePic {
  width: 310px;
  height: 298px;
  overflow: hidden;
}
.caseList .caseItem:hover .casePic {
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.2);
}
.caseList .caseItem:hover img {
  transform: scale(1.1);
}
/* 分页 */
.pagination >>> .el-pager li {
  padding: 0px 13px;
  background: #fff;
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #dbdbdb;
}
.pagination >>> .el-pagination .btn-next .el-icon {
  font-size: 24px;
}
.pagination >>> .el-pagination .btn-prev .el-icon {
  font-size: 24px;
}
.pagination >>> .el-pagination button {
  height: 36px;
  line-height: 36px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  padding: 0 6px;
}
/* 弹框 */
.cases >>> .el-dialog {
  border-radius: 30px;
}
.cases >>> .el-dialog__body {
  padding: 0 60px 60px;
}
.tips {
  display: inline-block;
  border-radius: 3px 3px 3px 3px;
  background: rgba(0, 132, 255, 0.1);
  color: #0084ff;
  padding: 0 4px;
}
.shopTit {
  top: -30px;
}
.shopInfo {
  background: #f7f9fa;
  border-radius: 10px 10px 10px 10px;
  max-height: 300px;
  overflow: hidden;
}
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  max-height: 270px;
}
</style>